export interface Notifications {
    id: number;
    title: string;
    message: string;
    start_time: string;
    end_time: string;
    created_at: string;
}

export interface NotificationFormData {
    title: string;
    message: string;
    start_time: string;
    end_time: string;
    id?: number;
}

export interface UserNotifications {
    id: number;
    title: string;
    message: string;
    user_email: string;
    user_organization_id: string;
    read: boolean;
    read_at: string;
    opened: boolean;
    opened_at: string;
    dismissed: boolean;
    dismissed_at: string;
    entity_name: string;
    entity_action: string;
    meta_data: string;
    created_at: string;
}

export interface NotificationReadPayload {
    notification_ids: number[];
}

export enum NotificationType {
    OrganizationBAAUpdated = 'Organization BAA Updated',
    PrimaryClinicalPolicyAdded = 'Primary Clinical Policy Added',
    PrimaryClinicalPolicyReviewedMedicalAffair = 'Primary Clinical Policy Medical Affair Reviewed',
    PrimaryClinicalPolicyReviewedManufacturer = 'Primary Clinical Policy Manufacturer Reviewed',
    HealthPlanAdded = 'Health Plan Added',
    HealthPlanClinicalPolicyAdded = 'Health Plan Clinical Policy Added',
    HealthPlanClinicalPolicyMedicalAffairReviewed = 'Health Plan Clinical Policy Medical Affair Reviewed',
    HealthPlanClinicalPolicyManufacturerReviewed = 'Health Plan Clinical Policy Manufacturer Reviewed',
    OutcomesReportAdded = 'Outcomes Report Added',
    OutcomesReportReviewedMedicalAffair = 'Outcomes Report Medical Affair Reviewed',
}

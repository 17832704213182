import { useTranslate } from 'hooks';

export const UnAuthorized = () => {
    const { t } = useTranslate();
    return (
        <div className="terms-of-service fluid-layout">
            <div className="fluid-layout-right w-100">
                <div className={`fluid-layout-right-header`}>
                    <h1>{t.UNAUTHORIZED}</h1>
                </div>
            </div>
        </div>
    );
};

import { AppConstant } from 'common/app.constants';
import { axios } from 'lib/axios';
import { TenantAppConfiguration, PlatformApp } from 'types';

export const updateCustomerApp = (
    id: number,
    tenantId: string,
    config: TenantAppConfiguration,
    custom_name?: string
): Promise<PlatformApp> => {
    const formData = new FormData();
    formData.append('id', `${id}`);
    custom_name && formData.append('custom_name', `${custom_name}`);
    if (config) {
        formData.append('config', JSON.stringify(config));
    }
    return axios.patch(
        AppConstant.API_ROUTES.CUSTOMER_APPS.replace('{tenant_id}', tenantId),
        formData
    );
};

import { useLocation } from 'react-router-dom';

const useIsPathNameMatching = () => {
    const { pathname } = useLocation();
    const isPathMatching = (path: string): boolean => {
        return (pathname.match(path) || []).length > 0;
    };
    return { isPathMatching };
};

export default useIsPathNameMatching;

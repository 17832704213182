import { Image } from 'react-bootstrap';

import CoebraPlaceholder from 'Assets/images/COEBRA-Logo-white.svg';
import { useTranslate } from 'hooks';

export const NotFound = () => {
    const { t } = useTranslate();
    return (
        <div className="terms-of-service fluid-layout">
            <div className="fluid-layout-left">
                <Image src={CoebraPlaceholder} aria-label="Coebra Logo" />
            </div>
            <div className="fluid-layout-right">
                <div className={`fluid-layout-right-header`}>
                    <h1>{t.NOT_FOUND}</h1>
                </div>
            </div>
        </div>
    );
};

import { createSlice } from '@reduxjs/toolkit';

interface LoaderState {
    fullscreenLoading: boolean;
    organizationGroupsLoading: boolean;
    organizationRolesLoading: boolean;
    organzationUsersLoading: boolean;
    vbcParticipantsLoading: boolean;
    myAppsLoading: boolean;
    fullScreenTextLoading: boolean;
    fullScreenText: string;
}

const initialState: LoaderState = {
    fullscreenLoading: false,
    organizationGroupsLoading: false,
    organizationRolesLoading: false,
    organzationUsersLoading: false,
    vbcParticipantsLoading: false,
    myAppsLoading: false,
    fullScreenTextLoading: false,
    fullScreenText: '',
};

export const loaderSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        showFullscreenLoader: (state) => {
            state.fullscreenLoading = true;
        },
        hideFullscreenLoader: (state) => {
            state.fullscreenLoading = false;
        },
        showOrganizationGroupsLoader: (state) => {
            state.organizationGroupsLoading = true;
        },
        hideOrganizationGroupsLoader: (state) => {
            state.organizationGroupsLoading = false;
        },
        showOrganizationUsersLoader: (state) => {
            state.organzationUsersLoading = true;
        },
        hideOrganizationUsersLoader: (state) => {
            state.organzationUsersLoading = false;
        },
        showOrganizationRolesLoader: (state) => {
            state.organizationRolesLoading = true;
        },
        hideOrganizationRolesLoader: (state) => {
            state.organizationRolesLoading = false;
        },
        showVBCParticipantsLoading: (state) => {
            state.vbcParticipantsLoading = true;
        },
        hideVBCParticipantsLoading: (state) => {
            state.vbcParticipantsLoading = false;
        },
        showMyAppsLoading: (state) => {
            state.myAppsLoading = true;
        },
        hideMyAppsLoading: (state) => {
            state.myAppsLoading = false;
        },
        showFullScreenTextLoading: (state, { payload }) => {
            state.fullScreenTextLoading = true;
            state.fullScreenText = payload;
        },
        hideFullScreenTextLoading: (state) => {
            state.fullScreenTextLoading = false;
        },
    },
});

export const {
    showFullscreenLoader,
    hideFullscreenLoader,
    showOrganizationGroupsLoader,
    hideOrganizationGroupsLoader,
    showOrganizationUsersLoader,
    hideOrganizationUsersLoader,
    showOrganizationRolesLoader,
    hideOrganizationRolesLoader,
    showVBCParticipantsLoading,
    hideVBCParticipantsLoading,
    showMyAppsLoading,
    hideMyAppsLoading,
    showFullScreenTextLoading,
    hideFullScreenTextLoading,
} = loaderSlice.actions;
export default loaderSlice.reducer;

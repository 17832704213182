import { FunctionComponent } from 'react';

import { useAppSelector } from 'hooks';

const FullScreenTextOverlay: FunctionComponent = () => {
    const { fullScreenTextLoading, fullScreenText } = useAppSelector((state) => state.loader);
    return (
        <>
            {fullScreenTextLoading && (
                <div className="file-link-overlay">
                    <div className="file-link-overlay-text">{fullScreenText}</div>
                </div>
            )}
        </>
    );
};

export default FullScreenTextOverlay;

import { useRouteQuery } from 'hooks';
import storage from 'utils/storage';

import { AccessTokenPayload } from '../types';

const useAccessTokenPayload = (): { payload: AccessTokenPayload; isChangePassword: boolean } => {
    const routeQuery = useRouteQuery();
    const { isChangePassword, ...loginRedirectData } = storage.getLoginRedirectData() || {};
    return {
        payload: {
            auth_code_flow: loginRedirectData,
            auth_response: {
                state: routeQuery.get('state') as string,
                client_info: routeQuery.get('client_info') as string,
                code: routeQuery.get('code') as string,
            },
        },
        isChangePassword: !!isChangePassword,
    };
};

export default useAccessTokenPayload;

import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { AppConstant } from 'common';
import { DocumentReviewType, DocumentReviewParams } from 'types';
import storage from 'utils/storage';
import { showErrorToast } from 'utils/toast';

import useAppSelector from './useAppSelector';
import useNavigateToVBCApp from './useNavigateToVBCApp';
import useTranslate from './useTranslate';

const useHandleDocumentReviewRoute = () => {
    const { t } = useTranslate();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { navigateToVBCApp } = useNavigateToVBCApp();
    const { myApps } = useAppSelector((state) => state.platformApps);
    const { userAccount } = useAppSelector((state) => state.auth);

    const handleInvalidReviewLink = () => {
        showErrorToast(t.DOCUMENT_REVIEW_LINK_NOT_VALID_ERR);
        navigate(AppConstant.ROUTE_PATHS.MY_PROFILE);
    };

    const setParamsAndNavigate = (
        params: DocumentReviewParams,
        appId: number,
        tenantId: string
    ) => {
        storage.setDocumentReviewParams(params);
        navigateToVBCApp(appId, tenantId);
    };

    useEffect(() => {
        if (pathname === AppConstant.ROUTE_PATHS.DOCUMENT_REVIEW_LANDING) {
            const {
                type,
                appId,
                tenantId,
                participantId,
                healthPlanId,
                outcomesReportId,
            }: DocumentReviewParams = {
                type: searchParams.get('type') as DocumentReviewType,
                appId: Number(searchParams.get('appId')),
                tenantId: searchParams.get('tenantId') || undefined,
                participantId: Number(searchParams.get('participantId')),
                healthPlanId: Number(searchParams.get('healthPlanId')),
                outcomesReportId: Number(searchParams.get('outcomesReportId')),
            };
            if (appId && tenantId) {
                const commonParams = { type, appId, tenantId };
                if (type === DocumentReviewType.PRIMARY_CLINICAL_POLICY && participantId) {
                    const params = {
                        ...commonParams,
                        participantId,
                        tabName: t.PRIMARY_POLICIES,
                    };
                    setParamsAndNavigate(params, appId, tenantId);
                } else if (type === DocumentReviewType.HEALTH_PLAN_POLICY && healthPlanId) {
                    const params = {
                        ...commonParams,
                        healthPlanId,
                        tabName: t.HEALTH_PLAN_POLICIES,
                    };
                    setParamsAndNavigate(params, appId, tenantId);
                } else if (type === DocumentReviewType.OUTCOMES_REPORT && outcomesReportId) {
                    const params = {
                        ...commonParams,
                        outcomesReportId,
                        tabName: t.OUTCOMES_REPORTS,
                    };
                    setParamsAndNavigate(params, appId, tenantId);
                } else if (type === DocumentReviewType.HEALTH_PLAN && healthPlanId) {
                    const params = {
                        ...commonParams,
                        healthPlanId,
                        tabName: t.ALL_HEALTH_PLANS,
                    };
                    setParamsAndNavigate(params, appId, tenantId);
                } else {
                    handleInvalidReviewLink();
                }
            } else {
                handleInvalidReviewLink();
            }
        }

        const params: DocumentReviewParams = storage.getDocumentReviewParams();
        const validParams = params.appId && params.tenantId;

        if (pathname === AppConstant.ROUTE_PATHS.MY_PROFILE && validParams) {
            navigateToVBCApp(params.appId as number, params.tenantId as string);
        }

        if (pathname === AppConstant.ROUTE_PATHS.VBC_DASHBOARD && validParams) {
            // based on params.type - navigate to diff route if needed
            if (params.type === DocumentReviewType.OUTCOMES_REPORT) {
                navigate(AppConstant.ROUTE_PATHS.VBC_REPORTS);
            } else if (params.type === DocumentReviewType.HEALTH_PLAN) {
                navigate(AppConstant.ROUTE_PATHS.VBC_HEALTH_PLANS);
            } else {
                navigate(AppConstant.ROUTE_PATHS.VBC_CLINICAL_POLICY_VERIFICATION);
            }
        }
    }, [pathname, userAccount, myApps]);
};

export default useHandleDocumentReviewRoute;

import { ILocalizeReturn } from '../types/localization.interface';
import { getLocalLanguage } from '../utils/local-language';

const useTranslate = (): ILocalizeReturn => {
    // const { locale } = useRouter(); GET locale from router/storage
    const t = getLocalLanguage();
    return { t };
};

export default useTranslate;

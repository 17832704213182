import { AppConstant } from 'common';
import { Organization } from 'types';

import useAppSelector from './useAppSelector';

const useIsCoeusOrg = (): {
    isCoeusOrg: boolean;
    checkIsCoeusOrg: (org: Organization) => boolean;
} => {
    const { organization } = useAppSelector((state) => state.organizationDetail);
    const checkIsCoeusOrg = (org: Organization) => {
        return org.name === AppConstant.COEUS_ORG_NAME;
    };
    return { isCoeusOrg: !!organization && checkIsCoeusOrg(organization), checkIsCoeusOrg };
};

export default useIsCoeusOrg;

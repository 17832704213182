import { FC, ReactElement } from 'react';

import { SVGIcons } from 'Assets/svg-icons';

export interface ISVGIconProps {
    icon: string;
    className: string;
    [x: string]: string;
}

export const SVGIcon: FC<ISVGIconProps> = ({ icon, ...props }): ReactElement => {
    const Icon = SVGIcons[icon];
    return <Icon aria-hidden="true" {...props} />;
};

import { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { AppConstant } from 'common';

import { BSButton } from '../Button';
import { SVGIcon } from '../SVGIcon';

interface OrganizationLinkProps {
    orgId: number;
    label?: string;
    icon?: string;
}

export const OrganizationLink: FC<OrganizationLinkProps> = ({ orgId, label, icon }) => {
    return (
        <NavLink
            className=" text-decoration-none"
            to={`${AppConstant.ROUTE_PATHS.ORGANIZATIONS}/${orgId}`}
        >
            <BSButton className="btn-icon" variant="outline-primary" size="sm" aria-label={label}>
                {icon && <SVGIcon icon={icon} className="svg-icon me-0" />}
                {label}
            </BSButton>
        </NavLink>
    );
};

import { useLocation, useNavigate } from 'react-router-dom';

import { AppConstant } from 'common';
import storage from 'utils/storage';

import useIsPathNameMatching from './useIsPathNameMatching';

const useRedirectAfterLogin = () => {
    const { pathname, search } = useLocation();
    const navigate = useNavigate();
    const { isPathMatching } = useIsPathNameMatching();

    const postLoginRedirectForRoutes: string[] = [
        AppConstant.ROUTE_PATHS.VBC_MARKETPLACE_VIEW_PAYER_OFFER,
        AppConstant.ROUTE_PATHS.VBC_MARKETPLACE_ALL_PAYER_OFFERS,
    ];

    const setPostLoginRedirect = () => {
        const setRedirect = postLoginRedirectForRoutes.some((route: string) =>
            isPathMatching(route)
        );
        setRedirect && storage.setPostLoginRedirectURL(`${pathname}${search}`);
    };

    const doPostLoginRedirect = () => {
        const redirectURL = storage.getPostLoginRedirectURL();
        if (redirectURL) {
            navigate(redirectURL);
            storage.clearPostLoginRedirectURL();
        }
    };

    return { setPostLoginRedirect, doPostLoginRedirect };
};

export default useRedirectAfterLogin;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';

import { RolesConstants } from 'common';
import { DecodedAccessToken } from 'features/auth';
import {
    UserAccountDetails,
    UserGroup,
    UserGroupRolePermission,
    Organization,
    UserOrganization,
    TpaConfig,
} from 'types';
import storage from 'utils/storage';

export interface AuthState {
    token: string;
    tokenExp: number;
    userAccount: Omit<UserAccountDetails, 'user_organizations' | 'groups'> | null;
    userOrg: Organization | null;
    userOrganizations: UserOrganization[];
    userGroups: UserGroup[];
    userPermissions: string[];
    activeTpaConfig: TpaConfig | null;
}

// Define the initial state using that type
const initialState: AuthState = {
    tokenExp: 0,
    token: '',
    userAccount: null,
    userOrg: null,
    userOrganizations: [],
    userGroups: [],
    userPermissions: [],
    activeTpaConfig: null,
};

const isTPAOrg = (user: UserAccountDetails) => {
    return user.groups.some((group: UserGroup) => group.role.name === RolesConstants.TPA);
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        decodeToken: (state, { payload }: PayloadAction<string | null>) => {
            if (payload) {
                const decodedToken: DecodedAccessToken = jwt_decode(payload);
                state.tokenExp = decodedToken.exp;
                state.token = payload;
                storage.setToken(payload);
            }
            storage.clearLoginRedirectData();
        },
        setUserAccount: (state, { payload }: PayloadAction<UserAccountDetails>) => {
            const { user_organizations, groups, ...userAccount } = payload;
            state.userAccount = userAccount;
            state.userOrganizations = user_organizations;

            const selectedOrg = Number(storage.getSelectedOrgId());

            const selectedUserOrg: UserOrganization =
                state.userOrganizations.find((org) => org.organization_id === selectedOrg) ||
                user_organizations[0];

            if (selectedUserOrg) {
                const { organization, organization_id } = selectedUserOrg;
                state.userOrg = organization;
                storage.setSelectedOrgId(organization_id);
                const filteredGroups = filterGroupsByOrg(groups, organization_id);
                state.userGroups = filteredGroups;
                state.userPermissions = extractPermissionsFromGroups(filteredGroups);

                if (organization?.tpa && isTPAOrg(payload)) {
                    state.activeTpaConfig = JSON.parse(organization.tpa.config);
                }
            }
        },
        setUserOrg: (state, { payload }: PayloadAction<number>) => {
            const defaultUserOrg = state.userOrganizations.find(
                (org) => org.organization_id === payload
            );
            if (defaultUserOrg) {
                state.userOrg = defaultUserOrg.organization;
            }

            if (defaultUserOrg?.organization?.tpa?.config) {
                state.activeTpaConfig = JSON.parse(defaultUserOrg.organization.tpa.config);
            }
        },
        acceptTermsOfService: (state) => {
            state.userAccount && (state.userAccount.terms_conditions_accepted = true);
        },
        setUpdateProfileFields: (state, { payload }: PayloadAction<UserAccountDetails>) => {
            if (state.userAccount) {
                state.userAccount.first_name = payload.first_name;
                state.userAccount.last_name = payload.last_name;
                state.userAccount.phone = payload.phone;
                state.userAccount.title = payload.title;
                state.userAccount.npi = payload.npi;
                state.userAccount.dea_number = payload.dea_number;
                state.userAccount.facility_address = payload.facility_address;
                state.userAccount.designee_title = payload.designee_title;
                state.userAccount.designee_first_name = payload.designee_first_name;
                state.userAccount.designee_last_name = payload.designee_last_name;
                state.userAccount.designee_phone = payload.designee_phone;
                state.userAccount.designee_email = payload.designee_email;
            }
        },
    },
});

const filterGroupsByOrg = (groups: UserGroup[], orgId: number): UserGroup[] =>
    groups.filter((group: UserGroup) => group.organization_id === orgId);

const extractPermissionsFromGroups = (groups: UserGroup[]): string[] => {
    return groups.reduce((permissions: string[], group: UserGroup) => {
        permissions = [
            ...permissions,
            ...group.role.role_permissions.map((rp: UserGroupRolePermission) => rp.permission_name),
        ];
        return permissions;
    }, []);
};

export const {
    decodeToken,
    setUserAccount,
    acceptTermsOfService,
    setUserOrg,
    setUpdateProfileFields,
} = authSlice.actions;

export default authSlice.reducer;

import { AppConstant } from 'common';
import { axios } from 'lib/axios';

import { NotificationReadPayload, Notifications, UserNotifications } from '../types';

export const getNotifications = (include_upcoming: boolean): Promise<Notifications[]> => {
    const qp = new URLSearchParams();
    qp.append('include_upcoming', include_upcoming.toString());
    return axios.get(`${AppConstant.API_ROUTES.NOTIFICATIONS_INFORMATIONAL}?${qp.toString()}`);
};

export const getTaskBasedNotifications = (): Promise<UserNotifications[]> => {
    return axios.get(AppConstant.API_ROUTES.NOTIFICATIONS_USER);
};

export const markNotificationAsRead = (payload: NotificationReadPayload): Promise<string> => {
    return axios.post(AppConstant.API_ROUTES.NOTIFICATION_READ, payload);
};

export const markNotificationAsDismissed = (payload: NotificationReadPayload): Promise<string> => {
    return axios.post(AppConstant.API_ROUTES.NOTIFICATION_DISMISSED, payload);
};

import { Fragment } from 'react';
import { Accordion } from 'react-bootstrap';

import { UserGuide } from 'components/UserGuide';
import { useAppSelector } from 'hooks';
import { dispatch } from 'store';
import { toggleMyAppsAccordion } from 'store/appSettingsSlice';

import { ReportIncident } from './ReportIncident';
import { SideBarItems } from './SideBarItems';
import { SwitchToAppNav } from './SwitchToAppNav';
import { ToggleSidebar } from './ToggleSidebar';

export const myAppsAccordianKeyName = 'switchToAccordian';

export const Sidebar = () => {
    const { userAccount } = useAppSelector((state) => state.auth);
    const { sidebarOpen, myAppsAccordionOpen } = useAppSelector((state) => state.appSettings);
    const activeAccordion = myAppsAccordionOpen ? myAppsAccordianKeyName : '';

    if (!userAccount) {
        return <></>;
    }

    return (
        <Fragment>
            <div className={`sidebar ${sidebarOpen ? 'expand-sidebar' : ''}`}>
                <Accordion
                    activeKey={activeAccordion}
                    onSelect={() => dispatch(toggleMyAppsAccordion())}
                >
                    <SwitchToAppNav />
                    <SideBarItems />
                    <ReportIncident />
                    <UserGuide />
                </Accordion>
            </div>
            <ToggleSidebar />
        </Fragment>
    );
};

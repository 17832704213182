import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { UserGroupRole } from 'types';

interface RolesState {
    allRoles: UserGroupRole[];
}

const initialState: RolesState = {
    allRoles: [],
};

export const rolesSlice = createSlice({
    name: 'roles',
    initialState,
    reducers: {
        setAllRoles: (state, { payload }: PayloadAction<UserGroupRole[]>) => {
            state.allRoles = payload;
        },
    },
});

export const { setAllRoles } = rolesSlice.actions;
export default rolesSlice.reducer;

import { FC, ReactElement, useState } from 'react';

import { useTranslate } from 'hooks';

import { BSButton } from '../Button';

interface ShowMoreTextProps {
    text: string;
    showInitialChars?: number;
}

export const ShowMoreText: FC<ShowMoreTextProps> = ({
    text,
    showInitialChars = 40,
}): ReactElement => {
    const { t } = useTranslate();
    const [showMore, setShowMore] = useState(true);
    if (text?.length >= showInitialChars && showMore) {
        return (
            <div className="d-flex align-items-center text-break">
                {text?.slice(0, showInitialChars)}
                ...
                <BSButton variant="link" className="text-nowrap" onClick={() => setShowMore(false)}>
                    {t.SHOW_MORE}
                </BSButton>
            </div>
        );
    }
    return <>{text}</>;
};

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { AppConstant } from 'common';
import { ErrorFallback } from 'components/ErrorFallback';
import { useTranslate } from 'hooks';
import { dispatch } from 'store';
import { decodeToken } from 'store/authSlice';
import { showFullscreenLoader, hideFullscreenLoader } from 'store/loaderSlice';
import { setPostLoginBanner } from 'store/toastSlice';

import { getAccessToken } from '../api/accessToken';
import { useAccessTokenPayload } from '../hooks';
import { AccessToken } from '../types';

const AuthResponse: React.FC = () => {
    const { t } = useTranslate();
    const { payload, isChangePassword } = useAccessTokenPayload();
    const navigate = useNavigate();
    const [error, setError] = useState(false);

    const handleGetAccessToken = async () => {
        try {
            dispatch(showFullscreenLoader());
            const accessToken: AccessToken = await getAccessToken(payload);
            dispatch(decodeToken(accessToken.access_token));
            dispatch(setPostLoginBanner(t.POST_LOGIN_BANNER));
            navigate(AppConstant.ROUTE_PATHS.PLATFORM);
        } catch {
            dispatch(decodeToken(null));
            setError(true);
        } finally {
            dispatch(hideFullscreenLoader());
        }
    };

    useEffect(() => {
        if (isChangePassword) {
            navigate(AppConstant.ROUTE_PATHS.PLATFORM);
        } else {
            handleGetAccessToken();
        }
    }, [payload, isChangePassword]);

    return <>{error && <ErrorFallback />}</>;
};

export default AuthResponse;

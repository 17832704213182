import { useEffect, useMemo, useState } from 'react';

import { AppConstant, PermissionConstants } from 'common';
import { useAppSelector, useIsAbleTo, useIsMonitoringEnabled, useTranslate } from 'hooks';

const useReportsTabs = () => {
    const { isContractingEntity, isDelegate, appDetails } = useAppSelector((state) => state.vbc);
    const { t } = useTranslate();
    const { isAbleTo } = useIsAbleTo();
    const showMonitoring = useIsMonitoringEnabled() && !isDelegate;

    const [showMonitoringReportsTab, setShowMonitoringReportsTab] = useState(false);
    const [showOutcomesReportsTab, setShowOutcomesReportsTab] = useState(false);
    const [showCombinedReport, setShowCombinedReport] = useState(false);
    const [show60DaysReport, setShow60DaysReport] = useState(false);
    const [showStatusReportsTab, setShowStatusReportsTab] = useState(false);
    const [defaultReportsTab, setDefaultReportsTab] = useState('');
    const [showClaimDataSummaryReport, setShowClaimDataSummaryReport] = useState(false);
    const [showPerformanceReport, setShowPerformanceReport] = useState(false);

    const shouldShowCustomReportsTab = (customReportId: string) =>
        !!appDetails?.config.customReportsConfiguration?.find(
            ({ reportId, enabled, forPayers }) =>
                reportId === customReportId && enabled && (isContractingEntity ? forPayers : true)
        );

    const determineDefaultReportsTab = (visibility: {
        shouldShowMonitoringReportsTab: boolean;
        shouldShowOutcomesReportsTab: boolean;
        shouldShowStatusReportTab: boolean;
        shouldShowCombinedReport: boolean;
        shouldShowClaimDataSummaryReport: boolean;
        shouldShow60DaysReport: boolean;
        showPerformanceReport: boolean;
    }) => {
        const {
            shouldShowMonitoringReportsTab,
            shouldShowOutcomesReportsTab,
            shouldShowStatusReportTab,
            shouldShowCombinedReport,
            shouldShow60DaysReport,
            shouldShowClaimDataSummaryReport,
            showPerformanceReport,
        } = visibility;

        let selectedReportTab = t.OUTCOMES_REPORTS;

        if (isContractingEntity) {
            switch (true) {
                case shouldShowOutcomesReportsTab:
                    selectedReportTab = t.OUTCOMES_REPORTS;
                    break;
                case shouldShowStatusReportTab:
                    selectedReportTab = t.STATUS_REPORTS;
                    break;
                case shouldShowMonitoringReportsTab:
                    selectedReportTab = t.MONITORING_REPORT;
                    break;
                case shouldShowCombinedReport || shouldShow60DaysReport:
                    selectedReportTab = t.CUSTOM_REPORTS;
                    break;
                case shouldShowClaimDataSummaryReport || showPerformanceReport:
                    selectedReportTab = t.OTHER_REPORTS;
                    break;
            }
        }
        return selectedReportTab;
    };

    const determineReportVisibility = () => {
        const {
            CLAIM_DATA_SUMMARY_REPORT,
            OUTCOMES_REPORT,
            VERTEX_CUSTOM_COMBINED_REPORT,
            VERTEX_CUSTOM_SIXTY_DAYS_PRE_POST_REPORT,
            BBB_CUSTOM_PERFORMANCE_REPORT,
        } = AppConstant.VBC_REPORTS_CONFIGURATION;

        return {
            shouldShowMonitoringReportsTab:
                !isDelegate && (isContractingEntity ? showMonitoring : true),
            shouldShowOutcomesReportsTab: isContractingEntity
                ? shouldShowCustomReportsTab(OUTCOMES_REPORT) && !isDelegate
                : !isDelegate,
            shouldShowStatusReportTab: !isContractingEntity && !isDelegate,
            shouldShowCombinedReport: shouldShowCustomReportsTab(VERTEX_CUSTOM_COMBINED_REPORT),
            shouldShowClaimDataSummaryReport:
                !isContractingEntity &&
                !isDelegate &&
                shouldShowCustomReportsTab(CLAIM_DATA_SUMMARY_REPORT),
            showPerformanceReport:
                !isContractingEntity &&
                !isDelegate &&
                shouldShowCustomReportsTab(BBB_CUSTOM_PERFORMANCE_REPORT),
            shouldShow60DaysReport: shouldShowCustomReportsTab(
                VERTEX_CUSTOM_SIXTY_DAYS_PRE_POST_REPORT
            ),
        };
    };

    useEffect(() => {
        const visibility = determineReportVisibility();

        setShowMonitoringReportsTab(
            isAbleTo(PermissionConstants.MONITORING_REPORT_GENERATE_MONITORING_REPORT) &&
                visibility.shouldShowMonitoringReportsTab
        );
        setShowOutcomesReportsTab(
            isAbleTo(PermissionConstants.OUTCOMES_REPORTS_VIEW) &&
                visibility.shouldShowOutcomesReportsTab
        );
        setShowCombinedReport(visibility.shouldShowCombinedReport);
        setShowClaimDataSummaryReport(visibility.shouldShowClaimDataSummaryReport);
        setShowPerformanceReport(visibility.showPerformanceReport);
        setShow60DaysReport(visibility.shouldShow60DaysReport);
        setShowStatusReportsTab(
            isAbleTo(PermissionConstants.STATUS_REPORTS_ACCESS) &&
                visibility.shouldShowStatusReportTab
        );

        setDefaultReportsTab(determineDefaultReportsTab(visibility));
    }, [isDelegate, isContractingEntity, appDetails, showMonitoring]);

    const showCustomReportsTab = useMemo(
        // Update following array when added more custom reports
        () =>
            isAbleTo(PermissionConstants.CUSTOM_REPORTS) &&
            [showCombinedReport, show60DaysReport].includes(true),
        [showCombinedReport, show60DaysReport]
    );

    const showOtherReportsTab = useMemo(
        // Update following array when added more other reports
        () => [showClaimDataSummaryReport, showPerformanceReport].includes(true),
        [showClaimDataSummaryReport, showPerformanceReport]
    );

    return {
        showMonitoringReportsTab,
        showCombinedReport,
        show60DaysReport,
        showOutcomesReportsTab,
        defaultReportsTab,
        showStatusReportsTab,
        showCustomReportsTab,
        showClaimDataSummaryReport,
        showOtherReportsTab,
        showPerformanceReport,
    };
};

export default useReportsTabs;

import { FC } from 'react';
import { NavLink as NavLinkBS } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { AppConstant } from 'common';
import { useAppSelector, useTranslate } from 'hooks';
import { dispatch } from 'store';
import { setUserOrg } from 'store/authSlice';

export const MyOrgsList: FC = () => {
    const { t } = useTranslate();
    const { userOrganizations, userOrg } = useAppSelector((state) => state.auth);
    const navigate = useNavigate();

    const handleOrgClick = (orgId: number) => {
        dispatch(setUserOrg(orgId));
        navigate(AppConstant.ROUTE_PATHS.MY_PROFILE);
    };

    if (userOrganizations.length < 2) {
        return <></>;
    }

    return (
        <>
            <div className="list-group">
                <div className="nav-menu-item">
                    <h6>{t.ORGANIZATIONS}</h6>
                    {userOrganizations?.map((org) => {
                        return (
                            <div key={org.organization_id} className="nav-link-action">
                                <NavLinkBS
                                    as="button"
                                    onClick={() => handleOrgClick(org.organization_id)}
                                    className={`list-group-item ${
                                        userOrg?.id === org.organization_id ? 'active-child' : ''
                                    }`}
                                >
                                    {org.organization.name}
                                </NavLinkBS>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

import { createSlice } from '@reduxjs/toolkit';

interface AppSettingsState {
    sidebarOpen: boolean;
    myAppsAccordionOpen: boolean;
}

const initialState: AppSettingsState = {
    sidebarOpen: true,
    myAppsAccordionOpen: false,
};

export const appSettingsSlice = createSlice({
    name: 'appSettings',
    initialState,
    reducers: {
        toggleSidebar: (state) => {
            state.sidebarOpen = !state.sidebarOpen;
        },
        toggleMyAppsAccordion: (state) => {
            state.myAppsAccordionOpen = !state.myAppsAccordionOpen;
        },
    },
});

export const { toggleSidebar, toggleMyAppsAccordion } = appSettingsSlice.actions;
export default appSettingsSlice.reducer;

import { AppConstant } from 'common/app.constants';
import { axios } from 'lib/axios';

export const getPlatformPatientIds = (participantId?: number): Promise<string[]> => {
    const qp = new URLSearchParams();
    participantId && qp.append('participant_id', `${participantId}`);

    return axios.get(`${AppConstant.API_ROUTES.VBC_PLATFORM_PATIENT_ID_LIST}?${qp.toString()}`);
};

export const getPayerPatientIdsList = (participantId?: number): Promise<string[]> => {
    const qp = new URLSearchParams();
    participantId && qp.append('participant_id', `${participantId}`);

    return axios.get(`${AppConstant.API_ROUTES.VBC_PAYER_PATIENT_IDS_LIST}?${qp.toString()}`);
};

export const getCoebraPatientIdFromPayerPatientId = (payer_patient_id: string): Promise<string> => {
    return axios.get(`${AppConstant.API_ROUTES.VBC_PLATFORM_PATIENT_ID}/${payer_patient_id}`);
};

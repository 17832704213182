import { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { useTranslate } from 'hooks';

import { BSBadge } from '../Badge';
import { SVGIcon } from '../SVGIcon';

import { NavBarItem } from './useNavBarData';

interface NavBarLinkItemProps {
    navBarItem: NavBarItem;
    accordianKey: string;
}

export const NavBarLinkItem: FC<NavBarLinkItemProps> = ({ navBarItem }) => {
    const { t } = useTranslate();
    const { link, name, count = 0, icon, matchExact = true } = navBarItem;

    return (
        <NavLink to={link} aria-label={t[name]} className="text-decoration-none" end={matchExact}>
            {({ isActive }) => (
                <div className={`accordion-item ${isActive ? '' : 'remove-active'}`}>
                    <div className="accordion-button">
                        {icon && (
                            <div className="nav-icon">
                                <SVGIcon icon={icon} className="svg-icon" />
                            </div>
                        )}
                        <span>{name}</span>
                        {count > 0 && (
                            <BSBadge
                                bg="danger"
                                className="me-2"
                                badgeText={`${count}`}
                                roundedFill
                            />
                        )}
                    </div>
                </div>
            )}
        </NavLink>
    );
};

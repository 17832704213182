import { createSlice } from '@reduxjs/toolkit';

import { TPA } from 'features/tpa-portal/types';

interface FilterState {
    selectedTPA: TPA | null;
}

const initialState: FilterState = {
    selectedTPA: null,
};

export const tpaSlice = createSlice({
    name: 'tpa',
    initialState,
    reducers: {
        setTPA: (state, { payload }) => {
            state.selectedTPA = payload;
        },
    },
});

export const { setTPA } = tpaSlice.actions;
export default tpaSlice.reducer;

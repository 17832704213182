import { FC, ReactElement } from 'react';

import { UnAuthorized } from 'components/UnAuthorized';
import { useIsAbleTo } from 'hooks';
interface ProtectedRouteProps {
    permission: string;
    children: ReactElement;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children, permission }) => {
    const { isAbleTo } = useIsAbleTo();
    if (!isAbleTo(permission)) {
        return <UnAuthorized />;
    }
    return <>{children}</>;
};

export default ProtectedRoute;

import { RolesConstants } from 'common';
import { UserGroup } from 'types';

import useAppSelector from './useAppSelector';

const useIsTPAUser = () => {
    const { userGroups } = useAppSelector((state) => state.auth);
    return userGroups.some((group: UserGroup) => group.role.name === RolesConstants.TPA);
};

export default useIsTPAUser;

import React, { useEffect } from 'react';

import { useTranslate } from 'hooks';
import { dispatch } from 'store';
import { hideFullscreenLoader, showFullscreenLoader } from 'store/loaderSlice';
import storage from 'utils/storage';
import { showErrorToast } from 'utils/toast';

import { getLoginRedirect } from '../api/loginRedirect';
import { LoginRedirect } from '../types';

const Login: React.FC = () => {
    const { t } = useTranslate();

    useEffect(() => {
        (async () => {
            try {
                dispatch(showFullscreenLoader());
                const redirectData: LoginRedirect = await getLoginRedirect();
                storage.setLoginRedirectData(redirectData);
                window.location.href = redirectData.auth_uri;
            } catch {
                // hide loader
                showErrorToast(t.ERROR_GET_LOGIN_REDIRECT_URL);
            } finally {
                dispatch(hideFullscreenLoader());
            }
        })();
    });

    return <></>;
};

export default Login;

import { AppConstant } from 'common';
import { BSBadge } from 'components/Elements';
import { getRuntimeEnv } from 'utils/environment';

export const EnvBadge = () => {
    const env = getRuntimeEnv();

    const getEnv = () => {
        switch (env) {
            case AppConstant.ENVIRONMENTS.LOCAL:
            case AppConstant.ENVIRONMENTS.DEV:
                return AppConstant.ENVIRONMENT_NAMES.DEV;
            case AppConstant.ENVIRONMENTS.QA:
                return AppConstant.ENVIRONMENT_NAMES.QA;
            case AppConstant.ENVIRONMENTS.SBX:
                return AppConstant.ENVIRONMENT_NAMES.SBX;
            case AppConstant.ENVIRONMENTS.BETA:
                return AppConstant.ENVIRONMENT_NAMES.BETA;
            default:
                return '';
        }
    };

    const currentEnv = getEnv();
    if (!currentEnv) {
        return <></>;
    }
    return <BSBadge fill badgeText={currentEnv} bg="info" showIcon={false}></BSBadge>;
};

import { RefObject, useState, useMemo, useEffect } from 'react';

const useIsElmVisibleOnScreen = (ref: RefObject<HTMLElement>, visibility = 0.7) => {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(
                ([entry]) => {
                    setIntersecting(entry.isIntersecting);
                },
                { threshold: visibility }
            ),
        [ref]
    );

    useEffect(() => {
        ref.current && observer.observe(ref.current as Element);
        return () => observer.disconnect();
    }, []);

    return isIntersecting;
};

export default useIsElmVisibleOnScreen;

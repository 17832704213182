import { useAppSelector, useIsCoeusOrg, useIsPathNameMatching, useTranslate } from 'hooks';
import { Organization, ParticipantAccessType, TenantAppAccess } from 'types';

const useOrganizationAccessType = (): {
    getAccessType: (organization: Organization) => string;
    getOrgAccessType: (organization: Organization) => string;
} => {
    const { checkIsCoeusOrg } = useIsCoeusOrg();
    const { t } = useTranslate();
    const { userAccount } = useAppSelector((state) => state.auth);
    const { isDelegate, isContractingEntity } = useAppSelector((state) => state.vbc);
    const { isPathMatching } = useIsPathNameMatching();

    const isVBC = isPathMatching('/apps/vbc');

    const getFilteredAppAccesses = (organization: Organization): TenantAppAccess[] => {
        if (organization.tenant_app_accesses?.length) {
            return organization.tenant_app_accesses.filter(
                (appAccess: TenantAppAccess) =>
                    appAccess.access_type === ParticipantAccessType.CONTRACTING_ENTITY ||
                    appAccess.access_type === ParticipantAccessType.DELEGATE
            );
        }
        return [];
    };
    const getOrgAccessType = (organization: Organization) => {
        const filteredAppAccesses = getFilteredAppAccesses(organization);
        if (filteredAppAccesses.length) {
            const accessTypes: string[] = filteredAppAccesses.reduce(
                (result: string[], appAccess: TenantAppAccess): string[] => {
                    if (!result.includes(appAccess.access_type)) {
                        result.push(appAccess.access_type);
                    }
                    return result;
                },
                []
            );
            return accessTypes.join(', ');
        } else if (organization.tpa) {
            return t.TPA;
        }
        return t.MANUFACTURER;
    };

    const getAccessType = (organization: Organization) => {
        const filteredAppAccesses = getFilteredAppAccesses(organization);
        if (organization.tpa) {
            return t.TPA;
        }
        if (filteredAppAccesses.length) {
            if (isVBC) {
                if (isDelegate) {
                    return ParticipantAccessType.DELEGATE;
                }
                if (isContractingEntity) {
                    return ParticipantAccessType.CONTRACTING_ENTITY;
                }
            }
            const checkDelegateAccess = filteredAppAccesses.find(
                (appAccess) =>
                    appAccess.delegate_user_email?.toLocaleLowerCase() ===
                    userAccount?.email?.toLocaleLowerCase()
            );
            if (checkDelegateAccess) {
                return checkDelegateAccess.access_type;
            }

            const accessTypes: string[] = filteredAppAccesses.reduce(
                (result: string[], appAccess: TenantAppAccess): string[] => {
                    if (
                        appAccess.access_type !== ParticipantAccessType.DELEGATE &&
                        !result.includes(appAccess.access_type)
                    ) {
                        result.push(appAccess.access_type);
                    }
                    return result;
                },
                []
            );
            return accessTypes.join(', ');
        }
        if (checkIsCoeusOrg(organization)) {
            return '';
        }
        return t.MANUFACTURER;
    };
    return { getAccessType, getOrgAccessType };
};

export default useOrganizationAccessType;

import { AppConstant } from 'common';
import { useTranslate } from 'hooks';

import { SVGIcon } from '../SVGIcon';

export const ReportIncident = () => {
    const { t } = useTranslate();

    return (
        <a className="text-decoration-none" href={`mailto:${AppConstant.REPORT_INCIDENT_EMAIL}`}>
            <div className="accordion-item remove-active report-incident">
                <div className="accordion-button">
                    <div className="nav-icon">
                        <SVGIcon icon="ReportSecurityIcon" className="svg-icon" />
                    </div>
                    <span>{t.REPORT_INCIDENT}</span>
                </div>
            </div>
        </a>
    );
};

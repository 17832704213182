import { AppConstant } from 'common';
import useOrganizationAccessType from 'features/organizations/hooks/useOrganizationAccessType';
import { useAppSelector, useIsCoeusUser, useTranslate } from 'hooks';
import { ParticipantAccessType, UserGroup } from 'types';
import { getRuntimeEnv } from 'utils/environment';

const checkUserHasAdminUserGroup = (userGroups: UserGroup[]) => {
    return userGroups.some((ug) => ug.name.toLocaleLowerCase().includes('admin'));
};

const UserAccessType = () => {
    const { userOrg, userGroups } = useAppSelector((state) => state.auth);
    const hasAdminGroup = checkUserHasAdminUserGroup(userGroups);
    const isCoeusUser = useIsCoeusUser();
    const { getAccessType } = useOrganizationAccessType();
    const { t } = useTranslate();
    const env = getRuntimeEnv();

    const hideAccessTypeBadge =
        env === AppConstant.ENVIRONMENTS.PROD || env === AppConstant.ENVIRONMENTS.SBX;

    if (!userOrg || hideAccessTypeBadge) {
        return <></>;
    }
    return (
        <>
            {userOrg && (
                <span className="org-type mb-0 show">
                    {isCoeusUser ? (
                        <>{`${t.COEUS} ${hasAdminGroup ? t.ADMIN : t.USER}`}</>
                    ) : (
                        <>
                            {getAccessType(userOrg)?.replaceAll(
                                ParticipantAccessType.CONTRACTING_ENTITY,
                                t.PAYER
                            )}
                        </>
                    )}
                </span>
            )}
        </>
    );
};

export default UserAccessType;

export enum DocumentReviewType {
    PRIMARY_CLINICAL_POLICY = 'PrimaryClinicalPolicy',
    HEALTH_PLAN_POLICY = 'HealthPlanPolicy',
    BAA = 'BAA',
    OUTCOMES_REPORT = 'OutcomesReport',
    HEALTH_PLAN = 'HealthPlan',
}

export interface DocumentReviewParams {
    type?: DocumentReviewType;
    appId?: number;
    tenantId?: string;
    participantId?: number;
    healthPlanId?: number;
    tabName?: string;
    organizationId?: number;
    outcomesReportId?: number;
    baaId?: number;
}

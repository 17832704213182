import { useAppSelector, useIsCoeusUser, useIsCustomerUser } from './';

const useIsMonitoringEnabled = (): boolean => {
    const isCoeusUser = useIsCoeusUser();
    const isCustomerUser = useIsCustomerUser();
    const { isContractingEntity, appDetails, currentParticipant } = useAppSelector(
        (state) => state.vbc
    );

    if (isCoeusUser || isCustomerUser) {
        return !!appDetails?.config?.monitoringRules;
    }

    if (isContractingEntity && currentParticipant) {
        return !!appDetails?.config?.participantConfiguration?.monitoringRules?.[
            `${currentParticipant.id}`
        ];
    }

    return false;
};

export default useIsMonitoringEnabled;

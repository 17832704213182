export class RolesConstants {
    public static SECURITY_ADMIN = 'Security Admin';
    public static SUPER_ADMIN = 'Super Admin';
    public static ADMIN_CUSTOMER_SUCCESS = 'Admin - Customer Success';
    public static REVIEWER_MEDICAL_AFFAIRS = 'Reviewer - Medical Affairs';
    public static ORGANIZATION_ADMIN = 'Organization Admin';
    public static USER_ALL = 'User - All';
    public static MANUFACTURER_VBC_ADMIN = 'Manufacturer VBC Admin';
    public static MANUFACTURER_VBC_POLICY_ACCESS = 'Manufacturer VBC Policy Access';
    public static PAYER_VBC_ADMIN = 'Payer VBC Admin';
    public static PAYER_VBC_USER = 'Payer VBC User';
    public static PAYER_VBC_POLICY_ACCESS = 'Payer VBC Policy Access';
    public static PAYER_VBC_CLAIMS_AND_REPORTS = 'Payer VBC Claims And Reports';
    public static PAYER_VBC_CLAIMS_ONLY = 'Payer VBC Claims Only';
    public static PAYER_VBC_REPORTS_ONLY = 'Payer VBC Reports Only';
    public static PAYER_VBC_PROVIDER = 'Payer VBC Provider';
    public static TPA = 'TPA';
}

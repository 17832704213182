import { RolesConstants } from 'common';
import { useAppSelector, useIsCoeusUser } from 'hooks';
import { UserGroup } from 'types';

const useIsCoeusMedReviewer = (): boolean => {
    const isCoeusUser = useIsCoeusUser();
    const { userGroups } = useAppSelector((state) => state.auth);
    if (isCoeusUser) {
        // check if the user has Medical reviewer role
        return userGroups.some(
            (group: UserGroup) => group.role.name === RolesConstants.REVIEWER_MEDICAL_AFFAIRS
        );
    }
    return false;
};

export default useIsCoeusMedReviewer;

export interface InvitedUsers {
    id: number;
    organization_id: number;
    invitation_status: string;
    invitation_email: string;
    invited_at: string;
}

export enum InviteStatus {
    Accepted = 'Accepted',
    Sent = 'Sent',
}

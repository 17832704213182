import { NavBarLinkItem } from './NavBarLinkItem';
import { NavBarItem, useNavBarData } from './useNavBarData';

export const SideBarItems = () => {
    const navBarData: NavBarItem[] = useNavBarData();

    return (
        <>
            {navBarData.map((item, index) => {
                if (item.condition()) {
                    return (
                        <NavBarLinkItem
                            key={item.link + item.name}
                            navBarItem={item}
                            accordianKey={`${index}-${item.name}`}
                        />
                    );
                }
            })}
        </>
    );
};

import { RolesConstants } from 'common';
import { useAppSelector, useIsCoeusUser } from 'hooks';
import { UserGroup } from 'types';

const useIsCoeusSuperAdmin = (): boolean => {
    const isCoeusUser = useIsCoeusUser();
    const { userGroups } = useAppSelector((state) => state.auth);
    if (isCoeusUser) {
        // check if the user has Super/Security Admin role
        return userGroups.some(
            (group: UserGroup) =>
                group.role.name === RolesConstants.SUPER_ADMIN ||
                group.role.name === RolesConstants.SECURITY_ADMIN
        );
    }
    return false;
};

export default useIsCoeusSuperAdmin;

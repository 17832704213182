import * as React from 'react';
import { Spinner } from 'react-bootstrap';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { ErrorFallback } from 'components/ErrorFallback';
import { FullscreenLoader } from 'components/FullscreenLoader';
import FullScreenTextOverlay from 'components/FullScreenTextOverlay/FullScreenTextOverlay';
import { ToastContainerComponent } from 'components/Toast';

import Store from '../store';

type AppProviderProps = {
    children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
    return (
        <React.Suspense
            fallback={
                <div className="flex items-center justify-center w-screen h-screen">
                    <Spinner />
                </div>
            }
        >
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Provider store={Store}>
                    <FullscreenLoader />
                    <FullScreenTextOverlay />
                    <ToastContainerComponent />
                    <Router>{children}</Router>
                </Provider>
            </ErrorBoundary>
        </React.Suspense>
    );
};

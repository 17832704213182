import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppConstant } from 'common';

interface FilterState {
    [tableName: string]: {
        searchTerm: string;
    };
}

const initialState: FilterState = {
    [AppConstant.TABLE_NAMES.CUSTOMERS_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.USERS_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.ORG_GROUPS_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.INVITED_USERS_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.PARTICIPANTS_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.HEALTH_PLANS_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.ORGANIZATIONS_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.PARTICIPANT_POLICIES_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.BAA_REVIEW_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.ALL_CLAIMS_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.CLAIM_REPORTS_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.OUTCOMES_REPORTS_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.ALL_TPAS_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.SYNTHETIC_VBC_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.TPA_CLAIMS_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.TPA_PATIENT_LIST]: {
        searchTerm: '',
    },
    [AppConstant.TABLE_NAMES.DRUG_FAILURE_REPORT_LIST]: {
        searchTerm: '',
    },
};

export const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setFilterSearchTerm: (
            state,
            { payload }: PayloadAction<{ tableName: string; searchTerm: string }>
        ) => {
            const { tableName, searchTerm } = payload;
            state[tableName] = { searchTerm: searchTerm.toLowerCase() };
        },
    },
});

export const { setFilterSearchTerm } = filterSlice.actions;
export default filterSlice.reducer;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Organization, UserAccountDetails, UserGroup, UserGroupRole } from 'types';

interface OrganizationDetailState {
    organization: Organization | null;
    organizationUsers: UserAccountDetails[];
    organizationRoles: UserGroupRole[];
    organizationGroups: UserGroup[];
}

const initialState: OrganizationDetailState = {
    organization: null,
    organizationUsers: [],
    organizationRoles: [],
    organizationGroups: [],
};

export const organizationDetailSlice = createSlice({
    name: 'organizationDetail',
    initialState,
    reducers: {
        setOrganization: (state, { payload }: PayloadAction<Organization>) => {
            state.organization = payload;
        },
        setOrganizationUsers: (state, { payload }: PayloadAction<UserAccountDetails[]>) => {
            state.organizationUsers = payload;
        },
        setOrganizationRoles: (state, { payload }: PayloadAction<UserGroupRole[]>) => {
            state.organizationRoles = payload;
        },
        setOrganizationGroups: (state, { payload }: PayloadAction<UserGroup[]>) => {
            state.organizationGroups = payload;
        },
    },
});

export const {
    setOrganization,
    setOrganizationUsers,
    setOrganizationRoles,
    setOrganizationGroups,
} = organizationDetailSlice.actions;
export default organizationDetailSlice.reducer;

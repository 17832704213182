import { FC, useEffect, useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import { useTranslate } from 'hooks';

import { BSButton } from '../Button';
import { SelectOption } from '../FormControl';
import { SVGIcon } from '../SVGIcon';

interface MultiSelectProps {
    options: SelectOption[];
    onSelectOptions: (selected: SelectOption[]) => void;
    controlId: string;
    label?: string;
    required?: boolean;
    invalidFeedback?: string;
    disabled?: boolean;
    searchable?: boolean;
    creatable?: boolean;
    onAddCustomOption?: (newCustomValue: string) => void;
}

export const MultiSelect: FC<MultiSelectProps> = ({
    options,
    label,
    required,
    onSelectOptions,
    controlId,
    invalidFeedback,
    disabled,
    searchable,
    creatable,
    onAddCustomOption,
}) => {
    const { t } = useTranslate();
    const { getValues, trigger } = useFormContext();
    const existingSelected = getValues(controlId);
    const [optionsSelect, setOptionsSelect] = useState(options);
    const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>(
        existingSelected?.length ? existingSelected : []
    );
    const [filteredOptions, setFilteredOptions] = useState<SelectOption[]>([]);
    const [inputValue, setInputValue] = useState('');

    const onChangeSelect = (checked: boolean, option: SelectOption) => {
        const updatedOptions = checked
            ? [...selectedOptions, option]
            : [...selectedOptions.filter((op) => op.value !== option.value)];

        setSelectedOptions(updatedOptions);
        onSelectOptions(updatedOptions);
        trigger(controlId);
    };

    useEffect(() => {
        if (!existingSelected || !existingSelected?.length) {
            setSelectedOptions([]);
        } else {
            setSelectedOptions(existingSelected);
        }
    }, [existingSelected]);

    useEffect(() => {
        if (inputValue) {
            setFilteredOptions(
                optionsSelect.filter((option) =>
                    option.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
                )
            );
        } else {
            setFilteredOptions(optionsSelect);
        }
    }, [inputValue, optionsSelect]);

    useEffect(() => {
        setOptionsSelect(options);
    }, [options]);

    return (
        <div className="multi-select">
            {label && (
                <Form.Label className="d-flex w-100 form-label">
                    {label} {required && <span className="mandatory text-danger">*</span>}
                </Form.Label>
            )}
            <Dropdown className="multi-select-dropdown w-100" autoClose="outside">
                <Dropdown.Toggle
                    variant="outline"
                    id={`dropdown-multi-select-${controlId}`}
                    className={`w-100 border justify-content-start ${
                        invalidFeedback ? 'is-invalid' : ''
                    } ${disabled ? 'disabled' : ''}`}
                >
                    {selectedOptions.length} items selected
                    <SVGIcon icon="ErrorIcon" className="svg-icon error-icon" />
                </Dropdown.Toggle>

                <Dropdown.Menu className="w-100 p-2">
                    {(searchable || creatable) && (
                        <Form.Control
                            className="mb-2"
                            value={inputValue}
                            placeholder={creatable ? t.SEARCH_OR_ADD : t.SEARCH}
                            onChange={(e) => setInputValue(e.currentTarget.value)}
                        />
                    )}
                    {filteredOptions.map((option, index) => (
                        <Form.Check
                            id={`${controlId}-check-${index}`}
                            key={option.value}
                            type="checkbox"
                            label={option.label}
                            onChange={(e) => onChangeSelect(e?.target?.checked, option)}
                            checked={selectedOptions.some(({ value }) => value === option.value)}
                            className="mb-2"
                        />
                    ))}
                    {creatable && !filteredOptions.length && (
                        <BSButton
                            variant="outline-primary"
                            size="sm"
                            type="button"
                            className="option-remove"
                            onClick={() => {
                                const customOption = { label: inputValue, value: inputValue };
                                setOptionsSelect([...optionsSelect, customOption]);
                                onChangeSelect(true, customOption);
                                setInputValue('');
                                onAddCustomOption && onAddCustomOption(inputValue);
                            }}
                        >
                            <SVGIcon icon="AddIcon" className="svg-icon me-0" />
                            {t.ADD} {inputValue}
                        </BSButton>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

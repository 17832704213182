import { useEffect, Suspense } from 'react';
import { Spinner } from 'react-bootstrap';
import {
    Navigate,
    Outlet,
    RouteObject,
    useLocation,
    useNavigate,
    useRoutes,
} from 'react-router-dom';

import { AppConstant, PermissionConstants } from 'common';
import { getAllRoles } from 'common/api/allRoles';
import { MainLayout } from 'components/Layout';
import { NotFound } from 'components/NotFound';
import ProtectedRoute from 'components/ProtectedRoute';
import { UnAuthorized } from 'components/UnAuthorized';
import { getUserAccountDetails } from 'features/auth';
import {
    useTranslate,
    useAppSelector,
    useHandleDocumentReviewRoute,
    useRedirectAfterLogin,
} from 'hooks';
import { dispatch } from 'store';
import { decodeToken, setUserAccount } from 'store/authSlice';
import { showFullscreenLoader, hideFullscreenLoader } from 'store/loaderSlice';
import { setAllRoles } from 'store/rolesSlice';
import { getFeatureFlags, getRuntimeEnv } from 'utils/environment';
import { lazyImport } from 'utils/lazyImport';
import storage from 'utils/storage';
import { showErrorToast } from 'utils/toast';

const { AuthRoutes } = lazyImport(() => import('features/auth'), 'AuthRoutes');
const { VerifyInvitesRoutes } = lazyImport(
    () => import('features/verifyInvites'),
    'VerifyInvitesRoutes'
);
const { MyProfile } = lazyImport(() => import('features/my-profile'), 'MyProfile');
const { CustomersRoutes } = lazyImport(() => import('features/customers'), 'CustomersRoutes');
const { TPAPortalRoutes } = lazyImport(() => import('features/tpa-portal'), 'TPAPortalRoutes');
const { NotificationsRoutes } = lazyImport(
    () => import('features/Notifications'),
    'NotificationsRoutes'
);
const { OrganizationRoutes } = lazyImport(
    () => import('features/organizations'),
    'OrganizationRoutes'
);
const { PlatformAppRoutes } = lazyImport(() => import('features/apps'), 'PlatformAppRoutes');
const { PostRegistrationRoutes } = lazyImport(
    () => import('features/postregistration'),
    'PostRegistrationRoutes'
);
const { VBCMarketplaceRoutes } = lazyImport(
    () => import('features/vbc-marketplace'),
    'VBCMarketplaceRoutes'
);
const { UIElements } = lazyImport(() => import('features/ui-elements'), 'UIElements');

const App = () => {
    const location = useLocation();
    const { t } = useTranslate();
    const navigate = useNavigate();
    const { token, userAccount, userOrg } = useAppSelector((state) => state.auth);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleDocumentReviewRoute = useHandleDocumentReviewRoute();
    const { setPostLoginRedirect, doPostLoginRedirect } = useRedirectAfterLogin();
    const accessToken = storage.getDecodedToken();

    const loadAllRoles = async () => {
        try {
            const allRoles = await getAllRoles();
            dispatch(setAllRoles(allRoles));
        } catch {
            dispatch(setAllRoles([]));
        }
    };

    const loadUserAccount = async (loadRoles = false) => {
        try {
            dispatch(showFullscreenLoader());
            loadRoles && loadAllRoles();
            const userAccountData = await getUserAccountDetails();
            dispatch(setUserAccount(userAccountData));
        } catch {
            showErrorToast(t.ERROR_GET_USER_ACCOUNT_DATA);
            dispatch(decodeToken(null));
            navigate(AppConstant.ROUTE_PATHS.LOGIN);
        } finally {
            dispatch(hideFullscreenLoader());
        }
    };

    useEffect(() => {
        if (!accessToken || accessToken.exp * 1000 < Date.now()) {
            setPostLoginRedirect();
            navigate(AppConstant.ROUTE_PATHS.LOGIN);
        } else {
            dispatch(decodeToken(storage.getToken()));
            doPostLoginRedirect();
        }
    }, [location]);

    useEffect(() => {
        if (!token || userAccount) {
            return;
        }
        loadUserAccount(true);
    }, [token]);

    useEffect(() => {
        const currentOrgId = Number(storage.getSelectedOrgId());
        if (userOrg && userOrg.id !== currentOrgId) {
            storage.setSelectedOrgId(userOrg.id);
            storage.clearVBCAppData();
            loadUserAccount();
        }
        if (userAccount && !userAccount?.terms_conditions_accepted) {
            navigate(AppConstant.ROUTE_PATHS.PLATFORM_TERMS_OF_SERVICE);
        } else if (userOrg && !userOrg?.validated) {
            navigate(AppConstant.ROUTE_PATHS.CONFIRM_ORG_DETAILS);
        }
    }, [userAccount, userOrg]);

    return (
        <MainLayout>
            <Suspense
                fallback={
                    <div className="fallback-spinner">
                        <Spinner />
                    </div>
                }
            >
                <Outlet />
            </Suspense>
        </MainLayout>
    );
};

const env = getRuntimeEnv();

// This is only for Development Purpose.
const routesOnlyForDev = [AppConstant.ENVIRONMENTS.LOCAL, AppConstant.ENVIRONMENTS.DEV].includes(
    env
)
    ? [
          {
              path: 'ui-elements',
              element: <UIElements />,
          },
      ]
    : [];

const vbcMarketplaceFeatureRoute = getFeatureFlags(AppConstant.FEATURE_FLAGS.VBC_MARKETPLACE)
    ? [
          {
              path: 'vbc-marketplace/*',
              element: <VBCMarketplaceRoutes />,
          },
      ]
    : [];

export const routes: RouteObject[] = [
    {
        path: '/',
        element: <Navigate to="auth" />,
    },
    {
        path: '/auth/*',
        element: <AuthRoutes />,
    },
    {
        path: '/invites/*',
        element: <VerifyInvitesRoutes />,
    },
    {
        path: '/postregistration/*',
        element: <PostRegistrationRoutes />,
    },
    {
        path: 'platform',
        element: <App />,
        children: [
            {
                path: '',
                element: <Navigate to="my-profile" />,
            },
            {
                path: 'my-profile',
                element: (
                    <ProtectedRoute permission={PermissionConstants.MY_USER_VIEW}>
                        <MyProfile />
                    </ProtectedRoute>
                ),
            },
            {
                path: 'document-review',
                // check useHandleDocumentReviewRoute hook
                element: <></>,
            },
            {
                path: 'manufacturers/*',
                element: (
                    <ProtectedRoute permission={PermissionConstants.MANUFACTURER_VIEW}>
                        <CustomersRoutes />
                    </ProtectedRoute>
                ),
            },
            {
                path: 'organizations/*',
                element: (
                    <ProtectedRoute permission={PermissionConstants.MY_USER_VIEW}>
                        <OrganizationRoutes />
                    </ProtectedRoute>
                ),
            },
            {
                path: 'tpa-portal/*',
                element: (
                    <ProtectedRoute permission={PermissionConstants.SUBMIT_TPA_CLAIM}>
                        <TPAPortalRoutes />
                    </ProtectedRoute>
                ),
            },
            {
                path: 'notifications/*',
                element: (
                    <ProtectedRoute
                        permission={PermissionConstants.INFORMATIONAL_NOTIFICATION_CREATE}
                    >
                        <NotificationsRoutes />
                    </ProtectedRoute>
                ),
            },
            ...vbcMarketplaceFeatureRoute,
            {
                path: 'apps/*',
                element: <PlatformAppRoutes />,
            },
            ...routesOnlyForDev,
        ],
    },
    {
        path: 'unauthorized',
        element: <UnAuthorized />,
    },
    {
        path: '404',
        element: <NotFound />,
    },
    {
        path: '*',
        element: <Navigate to="404" />,
    },
];

export const AppRoutes = () => {
    const element = useRoutes(routes);
    return <>{element}</>;
};

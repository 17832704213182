import { useMemo } from 'react';

import { RolesConstants } from 'common';
import { UserGroup } from 'types';

import useAppSelector from './useAppSelector';

const useIsProviderUser = () => {
    const { userGroups } = useAppSelector((state) => state.auth);
    const isProvider = useMemo(
        () =>
            userGroups.some(
                (group: UserGroup) => group.role.name === RolesConstants.PAYER_VBC_PROVIDER
            ),
        [userGroups]
    );
    return isProvider;
};

export default useIsProviderUser;

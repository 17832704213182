import { useEffect, useMemo, useState } from 'react';
import { CloseButton, Dropdown, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router';

import { AppConstant } from 'common';
import { BSBadge, BSButton, SVGIcon } from 'components/Elements';
import { useAppSelector, useTranslate } from 'hooks';
import { DocumentReviewType } from 'types';
import storage from 'utils/storage';
import { showErrorToast } from 'utils/toast';

import {
    getNotifications,
    getTaskBasedNotifications,
    markNotificationAsDismissed,
    markNotificationAsRead,
} from '../../api/getNotificationsList';
import { NotificationType, Notifications, UserNotifications } from '../../types';

export const NotificationsModal = () => {
    const { t } = useTranslate();
    const [notifications, setNotifications] = useState<Notifications[]>([]);
    const [taskBasedNotifications, setTaskBasedNotifications] = useState<UserNotifications[]>([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dismissLoading, setDismissLoading] = useState(false);
    const [readLoading, setReadLoading] = useState(false);
    const { userAccount } = useAppSelector((state) => state.auth);
    const navigate = useNavigate();

    const getNotificationsList = async () => {
        try {
            setLoading(true);
            const notificationsList = await getNotifications(false);
            const taskBasedNotificationList = await getTaskBasedNotifications();
            setNotifications(notificationsList);
            setTaskBasedNotifications(taskBasedNotificationList);
        } catch (error) {
            showErrorToast(t.NOTIFICATIONS_LIST_ERROR);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (userAccount) {
            getNotificationsList();
        }
    }, [userAccount]);

    useEffect(() => {
        if (isDropdownOpen) {
            getNotificationsList();
        }
    }, [isDropdownOpen]);

    const handleDropdownToggle = async (isOpen: boolean) => {
        setIsDropdownOpen(isOpen);
    };

    const formRedirectionURL = (notification: UserNotifications): string => {
        const notificationMetaData = JSON.parse(notification?.meta_data);
        const notificationType = `${notification.entity_name} ${notification.entity_action}`;

        switch (notificationType) {
            case NotificationType.OrganizationBAAUpdated: {
                const params = {
                    tabName: t.ALL_BAAS,
                    baaId: notificationMetaData?.organization_id,
                };
                storage.setDocumentReviewParams(params);
                return `${AppConstant.ROUTE_PATHS.ORGANIZATIONS_ALL}`;
            }

            case NotificationType.PrimaryClinicalPolicyAdded:
            case NotificationType.PrimaryClinicalPolicyReviewedMedicalAffair:
            case NotificationType.PrimaryClinicalPolicyReviewedManufacturer:
            case NotificationType.HealthPlanClinicalPolicyMedicalAffairReviewed:
            case NotificationType.HealthPlanClinicalPolicyManufacturerReviewed:
            case NotificationType.HealthPlanClinicalPolicyAdded: {
                const reviewLink = notificationMetaData?.review_link;
                if (reviewLink.startsWith('http')) {
                    const relativePath = new URL(reviewLink).pathname + new URL(reviewLink).search;
                    return relativePath;
                }
                return reviewLink;
            }

            case NotificationType.HealthPlanAdded:
                return `/platform/document-review?type=${DocumentReviewType.HEALTH_PLAN}&appId=${notificationMetaData.tenant_app_id}&tenantId=${notificationMetaData.tenant_id}&participantId=${notificationMetaData.participant_id}&healthPlanId=${notificationMetaData.health_plan_id}`;

            case NotificationType.OutcomesReportAdded:
            case NotificationType.OutcomesReportReviewedMedicalAffair:
                return `/platform/document-review?type=${DocumentReviewType.OUTCOMES_REPORT}&appId=${notificationMetaData.tenant_app_id}&tenantId=${notificationMetaData.tenant_id}&participantId=${notificationMetaData.participant_id}&outcomesReportId=${notificationMetaData.outcomes_report_id}`;

            default:
                return ''; // Fallback URL
        }
    };

    const handleNotificationClick = (notification: UserNotifications) => {
        try {
            if (!notification.read) {
                markNotificationAsRead({ notification_ids: [notification.id] });
            }
            const url = formRedirectionURL(notification);
            navigate(url);
        } catch (error) {
            showErrorToast(t.NOTIFICATION_CLICK_ERROR);
        }
    };

    const handleReadAll = async () => {
        if (taskBasedNotifications.length) {
            try {
                setReadLoading(true);
                const allNotificationIds = taskBasedNotifications.map(
                    (notification) => notification.id
                );
                await markNotificationAsRead({ notification_ids: allNotificationIds });
                await getNotificationsList();
            } catch (error) {
                showErrorToast(t.NOTIFICATIONS_LIST_ERROR);
            } finally {
                setReadLoading(false);
            }
        }
    };

    const handleDismissAllClick = async (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        try {
            event.stopPropagation();
            setTaskBasedNotifications([]);
            setDismissLoading(true);
            await markNotificationAsDismissed({
                notification_ids: taskBasedNotifications.map(
                    (taskNotification) => taskNotification.id
                ),
            });
            await getNotificationsList();
        } catch (error) {
            showErrorToast(t.NOTIFICATION_DISMISS_ERROR);
        } finally {
            setDismissLoading(false);
        }
    };

    const handleDismissClick = async (
        event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>,
        notificationId: number
    ) => {
        try {
            event.stopPropagation();
            event.preventDefault();
            setTaskBasedNotifications(
                taskBasedNotifications.filter((notification) => notification.id !== notificationId)
            );
            await markNotificationAsDismissed({
                notification_ids: [notificationId],
            });
            await getNotificationsList();
        } catch (error) {
            showErrorToast(t.NOTIFICATION_DISMISS_ERROR);
        }
    };

    const unreadNotifications = useMemo(
        () => taskBasedNotifications?.filter((taskNotification) => taskNotification.read === false),
        [taskBasedNotifications]
    );

    if (!userAccount) {
        return <></>;
    }

    return (
        <Dropdown className="notification-dropdown" onToggle={handleDropdownToggle}>
            <Dropdown.Toggle
                className="profile-menu d-flex justify-content-center align-items-center"
                id="notification-dropdown-basic"
                type="button"
                variant="link"
                as="a"
            >
                <div className="notification-icon-wrapper me-2">
                    <SVGIcon icon="NotificationIcon" className="svg-icon me-0" />
                    {(notifications.length > 0 || unreadNotifications.length > 0) && (
                        <span className="notification-dot"></span>
                    )}
                </div>
                {t.NOTIFICATIONS}
            </Dropdown.Toggle>
            <Dropdown.Menu className="pt-0 profile-menu-content" align="end">
                <div className="media mb-2 sticky-top">
                    <div className="media-body d-flex align-items-start justify-content-between w-100">
                        <h5>{t.NOTIFICATIONS}</h5>
                        <BSBadge
                            bg="danger"
                            className="me-2"
                            badgeText={`${unreadNotifications.length + notifications.length}`}
                            roundedFill
                        />
                    </div>
                </div>
                {loading && (
                    <div className="internal-loader">
                        <Spinner
                            as="span"
                            variant="primary"
                            className="m-2"
                            animation="border"
                            role="status"
                            aria-hidden="true"
                        />
                    </div>
                )}
                <div className="d-flex justify-content-end">
                    {!loading && unreadNotifications.length > 0 && (
                        <BSButton
                            variant="primary"
                            type="button"
                            aria-label={t.READ_ALL}
                            className="dismiss-notification me-2"
                            disabled={readLoading}
                            onClick={() => handleReadAll()}
                        >
                            {t.READ_ALL}
                        </BSButton>
                    )}
                    {!loading && taskBasedNotifications.length > 0 && (
                        <BSButton
                            variant="primary"
                            type="button"
                            aria-label={t.DISMISS_ALL}
                            className="dismiss-notification me-3"
                            disabled={dismissLoading}
                            onClick={(event) => handleDismissAllClick(event)}
                        >
                            {t.DISMISS_ALL}
                        </BSButton>
                    )}
                </div>
                {!loading &&
                    notifications?.map((notification) => (
                        <Dropdown.Item key={notification.id} className="mb-1">
                            <div className="d-flex flex-column w-100 pb-1 border-bottom">
                                <div className="banner-badge w-100">
                                    <SVGIcon icon="InfoIcon" className="me-2 info-page-notif" />
                                    <div className="notification-title">{notification.title}</div>
                                </div>
                                <p className="p-2 notification-message">{notification.message}</p>
                            </div>
                        </Dropdown.Item>
                    ))}

                {!loading &&
                    taskBasedNotifications?.map((notification) => (
                        <Dropdown.Item
                            key={notification.id}
                            className="mb-1 bg-transparent"
                            onClick={() => handleNotificationClick(notification)}
                        >
                            <div className="d-flex flex-column w-100 pb-1 border-bottom">
                                <div className="task-based-notification w-100 justify-content-between">
                                    <div className="d-flex flex-row align-items-center">
                                        <SVGIcon
                                            icon="WarningIcon"
                                            className="me-2 task-notification-icon"
                                        />
                                        <div
                                            className={`notification-title ${
                                                !notification.read ? 'fw-bold' : ''
                                            }`}
                                        >
                                            {notification.title}
                                        </div>
                                    </div>
                                    <CloseButton
                                        className="ms-auto"
                                        onClick={(e) => handleDismissClick(e, notification.id)}
                                    />
                                </div>
                                <div
                                    className={`notification-message ${
                                        !notification.read ? 'fw-bold' : ''
                                    }`}
                                >
                                    {notification.message}
                                </div>
                            </div>
                        </Dropdown.Item>
                    ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

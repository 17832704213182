import { createSlice } from '@reduxjs/toolkit';

import { getRandomNumber } from 'utils/getRandomNumber';
import storage from 'utils/storage';

interface ToastPayload {
    color: string;
    toastMessage: string;
    heading: string;
}

interface ToastObj extends ToastPayload {
    id: number;
}

interface ToastSliceState {
    toastsArray: ToastObj[];
    postLoginBanner: string;
}

const initialState: ToastSliceState = {
    toastsArray: [],
    postLoginBanner: '',
};

export const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        addToast: (state, action: { payload: ToastPayload }) => {
            state.toastsArray.push({ ...action.payload, id: getRandomNumber() });
        },
        removeToast: (state, action) => {
            state.toastsArray = state.toastsArray.filter((toast) => {
                return toast.id !== action.payload;
            });
        },
        setPostLoginBanner: (state, action: { payload: string }) => {
            state.postLoginBanner = action.payload;
            if (action.payload) {
                storage.setWarningMessageDismissed(false);
            }
        },
    },
});

export const { addToast, removeToast, setPostLoginBanner } = toastSlice.actions;
export default toastSlice.reducer;

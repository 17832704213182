import { dispatch } from 'store';
import { addToast } from 'store/toastSlice';
import { ToastTypes } from 'types/toast.interface';

const dispatchToast = (type: ToastTypes, toastMessage: string, heading: string) => {
    if (toastMessage?.trim().length) {
        dispatch(
            addToast({
                toastMessage,
                color: type,
                heading,
            })
        );
    }
};

export const showSuccessToast = (toastMessage: string) =>
    dispatchToast(ToastTypes.SUCCESS, toastMessage, 'Success');

export const showWarningToast = (toastMessage: string) =>
    dispatchToast(ToastTypes.WARNING, toastMessage, 'Warning');

export const showInfoToast = (toastMessage: string) =>
    dispatchToast(ToastTypes.INFO, toastMessage, 'Info');

export const showErrorToast = (toastMessage: string) =>
    dispatchToast(ToastTypes.ERROR, toastMessage, 'Error');

import { Navbar, Image } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import Logo from 'Assets/images/COEBRA-Logo-Text.svg';
import LogoTag from 'Assets/images/COEUS-Logo-Tag.svg';
import { AppConstant } from 'common';
import { NotificationsModal } from 'features/Notifications/components/NotificationsModal/NotificationsModal';
import { useAppSelector, useTranslate } from 'hooks';

import AppName from '../../AppName';
import { EnvBadge } from '../../ENVBadge';
import { BSButton } from '../Button';

import { MyAccount } from './MyAccount';

export const Header = () => {
    const { t } = useTranslate();
    const { sidebarOpen } = useAppSelector((state) => state.appSettings);

    return (
        <Navbar
            className={`custom-navbar ${sidebarOpen ? 'expand-sidebar' : ''}`}
            bg="light"
            variant="light"
            expand="lg"
        >
            <div className="logo-container">
                <Navbar.Brand className="ms-3 p-0 d-flex">
                    <NavLink to={AppConstant.ROUTE_PATHS.MY_PROFILE}>
                        <Image src={LogoTag} aria-label="Brand Logo Tag" />
                    </NavLink>
                </Navbar.Brand>
                <Navbar.Brand className="d-flex align-items-center product-logo">
                    <NavLink to={AppConstant.ROUTE_PATHS.MY_PROFILE}>
                        <Image src={Logo} aria-label="Brand Logo" />
                    </NavLink>
                </Navbar.Brand>
            </div>

            <div className="active-vbc">
                <AppName />
                <EnvBadge />
            </div>

            <div className="ms-auto d-flex nav-action">
                <NotificationsModal />
                <BSButton
                    variant="link"
                    icon="HeadsetIcon"
                    size="sm"
                    aria-label={t.SUPPORT}
                    as="a"
                    target="_blank"
                    rel="noreferrer"
                    href={`${AppConstant.JIRA_HELP_DESK_LINK}`}
                >
                    {t.SUPPORT}
                </BSButton>
                <MyAccount />
                <Navbar.Toggle className="ms-3 d-none" aria-controls="basic-navbar-nav" />
            </div>
        </Navbar>
    );
};

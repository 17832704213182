import { AppConstant } from 'common';
import { useTranslate } from 'hooks';

import { BSButton } from '../Elements';

export const ErrorFallback = () => {
    const { t } = useTranslate();
    return (
        <div className="d-flex h-100 w-100 justify-content-center align-items-center">
            <div className="shadow p-5 rounded-3" role="alert">
                <h2 className="text-lg font-semibold">{t.SOMETHING_WENT_WRONG} </h2>
                <BSButton
                    className="mt-4 mx-auto"
                    onClick={() =>
                        window.location.assign(
                            `${window.location.origin}${AppConstant.ROUTE_PATHS.LOGOUT}`
                        )
                    }
                >
                    {t.REFRESH}
                </BSButton>
            </div>
        </div>
    );
};

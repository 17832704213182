import { FC, ReactElement } from 'react';
import { Button, ButtonProps, Spinner } from 'react-bootstrap';

import { SVGIcon } from '../SVGIcon';

export interface BSButtonProps extends ButtonProps {
    loading?: boolean;
    icon?: string;
}

export const BSButton: FC<BSButtonProps> = ({
    type,
    children,
    onClick,
    variant,
    size,
    className,
    loading,
    icon,
    ...buttonProps
}): ReactElement => {
    return (
        <Button
            className={className}
            type={type}
            onClick={onClick}
            disabled={loading}
            variant={variant}
            size={size}
            {...buttonProps}
        >
            {icon && <SVGIcon icon={icon} className="svg-icon" />}
            {children}
            {loading && (
                <Spinner
                    as="span"
                    className="m-2"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
            )}
        </Button>
    );
};

import { FC } from 'react';
import { Image } from 'react-bootstrap';

import Logo from 'Assets/images/COEBRA-Logo.svg';
import { useAppSelector } from 'hooks';

export const FullscreenLoader: FC = () => {
    const { fullscreenLoading } = useAppSelector((state) => state.loader);
    return (
        <>
            {fullscreenLoading && (
                <div className="loader">
                    <div className="loader-content">
                        <Image src={Logo} className="loader-logo" aria-label="Brand Logo" />
                        <div className="loader-line"></div>
                    </div>
                </div>
            )}
        </>
    );
};

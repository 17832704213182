import { useCallback, useMemo } from 'react';

import { SVGIcon } from 'components/Elements';
import useOrganizationAccessType from 'features/organizations/hooks/useOrganizationAccessType';
import { useAppSelector, useTranslate } from 'hooks';
import { ParticipantAccessType } from 'types';
import { getCOEBRAPublicStorageName } from 'utils/environment';

export const UserGuide = () => {
    const { t } = useTranslate();
    const { userOrg } = useAppSelector((state) => state.auth);
    const { getAccessType } = useOrganizationAccessType();
    const userAccessType = userOrg ? getAccessType(userOrg) : '';
    const coebraPublicStorage = useMemo(() => getCOEBRAPublicStorageName(), []);

    const getUserGuideURL = useCallback(
        (fileName: string) =>
            `https://${coebraPublicStorage}.blob.core.windows.net/public/${fileName}`,
        []
    );

    let userGuideLink = '';
    if (userAccessType.includes(t.MANUFACTURER)) {
        userGuideLink = getUserGuideURL('COEBRA User Guide_Manufacturer2024_vs1.pdf');
    } else if (userAccessType.includes(ParticipantAccessType.CONTRACTING_ENTITY)) {
        userGuideLink = getUserGuideURL('COEBRA User Guide_Payer2024_vs1.pdf');
    }

    if (!userGuideLink) {
        return <></>;
    }

    return (
        <a href={userGuideLink} target="_blank" rel="noreferrer">
            <div className="accordion-item remove-active">
                <div className="accordion-button">
                    <div className="nav-icon">
                        <SVGIcon icon="InfoIcon" className="svg-icon" />
                    </div>
                    <span>{t.USER_GUIDE}</span>
                </div>
            </div>
        </a>
    );
};

import { AppConstant } from 'common';

import useAppSelector from './useAppSelector';
import useIsCoeusOrg from './useIsCoeusOrg';

const useIsCoeusUser = (): boolean => {
    const { userAccount, userOrg } = useAppSelector((state) => state.auth);
    const { checkIsCoeusOrg } = useIsCoeusOrg();
    return !!(
        userAccount &&
        userOrg &&
        userAccount?.email.match(AppConstant.ONE_COEUS_EMAIL_DOMAIN)?.length &&
        checkIsCoeusOrg(userOrg)
    );
};

export default useIsCoeusUser;

import * as React from 'react';

import { Head } from '../Head';

type ContentLayoutProps = {
    children: React.ReactNode;
    title: string;
};

export const ContentLayout = ({ children, title }: ContentLayoutProps) => {
    return (
        <>
            <Head title={title} />
            <div className="layout">{children}</div>
        </>
    );
};

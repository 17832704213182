import { FC } from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

import { useAppSelector } from 'hooks';
import { dispatch } from 'store';
import { removeToast } from 'store/toastSlice';
import { ToastTypes } from 'types/toast.interface';

export const ToastContainerComponent: FC = () => {
    const toastArray = useAppSelector((state) => state.toast.toastsArray);

    const handleClose = (id: number) => {
        dispatch(removeToast(id));
    };

    return (
        <>
            <ToastContainer className="p-3" position="top-end">
                {toastArray?.map((toast) => {
                    return (
                        <Toast
                            key={toast.id}
                            className=" m-1"
                            bg={toast.color}
                            onClose={() => handleClose(toast.id)}
                        >
                            <Toast.Header closeButton>
                                {/* <img src="" className="rounded me-2" alt="" /> */}
                                <strong className="me-auto">{toast.heading}</strong>
                            </Toast.Header>
                            <Toast.Body className={getTextColor(toast.color)}>
                                {toast.toastMessage}
                            </Toast.Body>
                        </Toast>
                    );
                })}
            </ToastContainer>
        </>
    );
};

const getTextColor = (color: string) => {
    return color === ToastTypes.INFO || color === ToastTypes.WARNING ? 'text-black' : 'text-white';
};

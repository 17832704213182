import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { AppConstant } from 'common';
import { useTranslate } from 'hooks';
import { dispatch } from 'store';
import { hideFullscreenLoader, showFullscreenLoader } from 'store/loaderSlice';
import storage from 'utils/storage';
import { showErrorToast } from 'utils/toast';

import { getChangePasswordRedirect } from '../api/changePasswordRedirect';
import { LoginRedirect } from '../types';

const ChangePassword: React.FC = () => {
    const { t } = useTranslate();
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                dispatch(showFullscreenLoader());
                const redirectData: LoginRedirect = await getChangePasswordRedirect();
                storage.setLoginRedirectData({ ...redirectData, isChangePassword: true });
                window.location.href = redirectData.auth_uri;
            } catch {
                // hide loader
                showErrorToast(t.ERROR_GET_CHANGE_PASSWORD_REDIRECT_URL);
                navigate(AppConstant.ROUTE_PATHS.PLATFORM);
            } finally {
                dispatch(hideFullscreenLoader());
            }
        })();
    });

    return <></>;
};

export default ChangePassword;

import * as React from 'react';

import { Header, Sidebar } from 'components/Elements';

type MainLayoutProps = {
    children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
    return (
        <div className="main">
            <Header />
            <div className="wrapper overflow-auto">
                <Sidebar />
                <React.Fragment>{children}</React.Fragment>
            </div>
        </div>
    );
};

import { AppConstant } from 'common';

const windowObj = window as any;

export const getRuntimeEnv = (): string => {
    return windowObj.__RUNTIME_CONFIG__.BE_ENV;
};

export const getPowerBiEnvConfig = () => {
    return {
        POWER_BI_WORKSPACE_ID: windowObj.__RUNTIME_CONFIG__.POWER_BI_WORKSPACE_ID,
        POWER_BI_SAMPLE_REPORT_ID: windowObj.__RUNTIME_CONFIG__.POWER_BI_SAMPLE_REPORT_ID,
        POWER_BI_VBC_DASHBOARD_REPORT_ID:
            windowObj.__RUNTIME_CONFIG__.POWER_BI_VBC_DASHBOARD_REPORT_ID,
    };
};

export const getFeatureFlags = (feature: string) => {
    const featureFlagMap = {
        [AppConstant.FEATURE_FLAGS.VBC_MARKETPLACE]:
            windowObj.__RUNTIME_CONFIG__.VBC_MARKETPLACE_FEATURE === 'true', // process.env adds boolean values as string
    };

    return featureFlagMap[feature];
};

export const getCOEBRAStatusPageURL = (): string => {
    return windowObj.__RUNTIME_CONFIG__.COEBRA_STATUS_PAGE_URL;
};

export const getCOEBRAPublicStorageName = (): string => {
    return windowObj.__RUNTIME_CONFIG__.PUBLIC_STORAGE_NAME;
};

import { Routes, Route, Navigate } from 'react-router-dom';

import AuthResponse from '../components/AuthResponse';
import ChangePassword from '../components/ChangePassword';
import Login from '../components/Login';
import Logout from '../components/Logout';

export const AuthRoutes = () => {
    return (
        <Routes>
            <Route path="login" element={<Login />} />
            <Route path="auth-response" element={<AuthResponse />} />
            <Route path="change-password" element={<ChangePassword />} />
            <Route path="logout" element={<Logout />} />
            <Route path="*" element={<Navigate to="login" />} />
        </Routes>
    );
};

import { Accordion } from 'react-bootstrap';

import { useAppSelector, useTranslate } from 'hooks';
import { dispatch } from 'store';
import { toggleSidebar } from 'store/appSettingsSlice';

import { MyAppList } from '../MyAppsList';
import { MyOrgsList } from '../MyOrgsList';
import { SVGIcon } from '../SVGIcon';

import { myAppsAccordianKeyName } from './Sidebar';

export const SwitchToAppNav = () => {
    const { t } = useTranslate();
    const { sidebarOpen } = useAppSelector((state) => state.appSettings);

    const handleSwitchToClick = () => {
        if (!sidebarOpen) {
            dispatch(toggleSidebar());
        }
    };

    return (
        <Accordion.Item eventKey={myAppsAccordianKeyName} className="switch-item remove-active">
            <Accordion.Header onClick={handleSwitchToClick}>
                <div className="nav-icon">
                    <SVGIcon icon="MenuIcon" className="svg-icon" />
                </div>
                <span>{t.SWITCH_TO}</span>
                <div className="expand-icon">
                    <SVGIcon icon="ExpandIcon" className="svg-icon" />
                </div>
            </Accordion.Header>
            <Accordion.Body>
                <MyOrgsList />
                <MyAppList />
            </Accordion.Body>
        </Accordion.Item>
    );
};

import { dispatch } from 'store';
import { toggleSidebar } from 'store/appSettingsSlice';

import { BSButton } from '../Button';
import { SVGIcon } from '../SVGIcon';

export const ToggleSidebar = () => {
    return (
        <BSButton className="sidebar-toggle" onClick={() => dispatch(toggleSidebar())}>
            <SVGIcon icon="ExpandIcon" className="svg-icon" />
        </BSButton>
    );
};

import { AppProvider } from 'providers/app';
import { AppRoutes } from 'routes/routes';
import './App.scss';

const App = () => {
    return (
        <AppProvider>
            <AppRoutes />
        </AppProvider>
    );
};

export default App;

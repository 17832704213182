import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppConstant } from 'common';
import { useTranslate } from 'hooks';
import storage from 'utils/storage';
import { showErrorToast } from 'utils/toast';

import { logoutUser } from '../api/logout';

const Logout: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslate();

    useEffect(() => {
        (async () => {
            try {
                const logoutURL = await logoutUser();
                storage.clearLocalStorage();
                window.location.href = logoutURL;
            } catch {
                showErrorToast(t.ERROR_LOGOUT_USER);
                storage.clearLocalStorage();
                navigate(AppConstant.ROUTE_PATHS.LOGIN);
            }
        })();
    });
    return <></>;
};

export default Logout;

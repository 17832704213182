import { AppConstant } from 'common';
import { axios } from 'lib/axios';
import { Organization } from 'types';

export const getOrganizations = (orgNameSearchTerm?: string): Promise<Organization[]> => {
    const qp = new URLSearchParams();

    orgNameSearchTerm && qp.append('name', orgNameSearchTerm);

    return axios.get(`${AppConstant.API_ROUTES.ORGANIZATION_LIST}?${qp}`);
};

export const getAllBAAsOrganizations = (baaStatuses?: string[]): Promise<Organization[]> => {
    const qp = new URLSearchParams();

    baaStatuses?.forEach((status) => {
        qp.append('baa_statuses', status);
    });

    qp.append('contracting_entity_organization', 'true');

    return axios.get(`${AppConstant.API_ROUTES.ORGANIZATION_LIST}?${qp}`);
};

import { FC, ReactElement } from 'react';
import { BadgeProps, Form, Spinner } from 'react-bootstrap';

import { useTranslate } from 'hooks';
import { dispatch } from 'store';
import { hideFullScreenTextLoading, showFullScreenTextLoading } from 'store/loaderSlice';

import { BSBadge } from '../Badge';
import { BSButton } from '../Button';
import { SVGIcon } from '../SVGIcon';

interface FileLinkProps extends BadgeProps {
    label?: string;
    fileName?: string;
    fileLink?: string;
    onClose: () => void;
    badgeText?: string;
    className?: string;
    loading?: boolean;
    showClose?: boolean;
    showNewTabOverlay?: boolean;
    helpText?: string;
}

export const FileLink: FC<FileLinkProps> = ({
    label,
    fileName,
    fileLink,
    onClose,
    badgeText,
    className = '',
    loading,
    showClose = true,
    showNewTabOverlay = false,
    helpText,
}): ReactElement => {
    const { t } = useTranslate();
    const showMessageAndRedirect = () => {
        dispatch(showFullScreenTextLoading(t.DOCUMENT_REDIRECTION_MESSAGE));

        setTimeout(() => {
            window.open(fileLink, '_blank');
            dispatch(hideFullScreenTextLoading());
        }, 2000);
    };

    const handleKeyPress = (event: { key: string }) => {
        if (event.key === 'Enter') {
            showMessageAndRedirect();
        }
    };

    const linkProps = showNewTabOverlay
        ? {
              onClick: showMessageAndRedirect,
              onKeyDown: handleKeyPress,
          }
        : {
              href: fileLink,
              target: '_blank',
          };

    return (
        <Form.Group className={`${className} form-group position-relative`}>
            {label && (
                <Form.Label className="d-flex align-items-center">
                    {label}
                    {badgeText && (
                        <BSBadge fill badgeText={badgeText} className="ms-auto"></BSBadge>
                    )}
                </Form.Label>
            )}
            <div>
                <div className="pdf-download">
                    {loading ? (
                        <Spinner size="sm" variant="primary"></Spinner>
                    ) : (
                        <>
                            <a
                                className="file-link text-decoration-none text-truncate w-100"
                                title={fileName}
                                rel="noreferrer"
                                {...linkProps}
                            >
                                {fileName}
                            </a>
                            {showClose && (
                                <BSButton
                                    className="button-close"
                                    type="button"
                                    variant="primary"
                                    onClick={onClose}
                                >
                                    <SVGIcon icon="CloseIcon" className="svg-icon " />
                                </BSButton>
                            )}
                        </>
                    )}
                </div>
                {helpText && (
                    <div>
                        <span className="text-secondary">{helpText}</span>
                    </div>
                )}
            </div>
        </Form.Group>
    );
};

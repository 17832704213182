import jwt_decode from 'jwt-decode';

import { DecodedAccessToken, LoginRedirect } from 'features/auth';
import { DocumentReviewParams } from 'types';

interface VBCAppStorageData {
    appId: number;
    appTenantId: number;
    appOrgId: number;
    appName: string;
}

const storagePrefix = 'platform_';

const getStorage = (): Storage => {
    return window.sessionStorage;
};

const storage = {
    getToken: (): string => {
        return getStorage().getItem(`${storagePrefix}token`) as string;
    },
    setToken: (token: string) => {
        getStorage().setItem(`${storagePrefix}token`, token);
    },
    clearToken: () => {
        getStorage().removeItem(`${storagePrefix}token`);
    },
    setLoginRedirectData: (loginRedirect: LoginRedirect) => {
        getStorage().setItem(`${storagePrefix}login_redirect`, JSON.stringify(loginRedirect));
    },
    getLoginRedirectData: (): LoginRedirect => {
        return JSON.parse(getStorage().getItem(`${storagePrefix}login_redirect`) as string);
    },
    clearLoginRedirectData: () => {
        getStorage().removeItem(`${storagePrefix}login_redirect`);
    },
    getDecodedToken: (): DecodedAccessToken | null => {
        return storage.getToken() ? jwt_decode(storage.getToken()) : null;
    },
    getSelectedOrgId: (): string | null => {
        return getStorage().getItem(`${storagePrefix}selected_org_id`) as string;
    },
    setSelectedOrgId: (orgId: number) => {
        getStorage().setItem(`${storagePrefix}selected_org_id`, `${orgId}`);
    },
    removeSelectedOrgId: () => {
        getStorage().removeItem(`${storagePrefix}selected_org_id`);
    },
    getVBCAppData: (): VBCAppStorageData => {
        return JSON.parse(getStorage().getItem(`${storagePrefix}vbc_app`) as string);
    },
    setVBCAppData: (vbcAppData: VBCAppStorageData) => {
        getStorage().setItem(`${storagePrefix}vbc_app`, JSON.stringify(vbcAppData));
    },
    clearVBCAppData: () => {
        getStorage().removeItem(`${storagePrefix}vbc_app`);
    },
    clearLocalStorage: () => {
        return getStorage().clear();
    },
    getDocumentReviewParams: (): DocumentReviewParams => {
        return (
            JSON.parse(getStorage().getItem(`${storagePrefix}document_review_params`) as string) ||
            {}
        );
    },
    setDocumentReviewParams: (documentReviewParams: DocumentReviewParams) => {
        getStorage().setItem(
            `${storagePrefix}document_review_params`,
            JSON.stringify(documentReviewParams)
        );
    },
    clearDocumentReviewParams: () => {
        getStorage().removeItem(`${storagePrefix}document_review_params`);
    },
    getWarningMessageDismissed: (): string => {
        return getStorage().getItem(`${storagePrefix}warning-dismissed`) as string;
    },
    setWarningMessageDismissed: (dismissed: boolean) => {
        getStorage().setItem(`${storagePrefix}warning-dismissed`, `${dismissed}`);
    },
    setPostLoginRedirectURL: (route: string) => {
        getStorage().setItem(`${storagePrefix}post-login-redirect`, `${route}`);
    },
    getPostLoginRedirectURL: (): string => {
        return getStorage().getItem(`${storagePrefix}post-login-redirect`) as string;
    },
    clearPostLoginRedirectURL: () => {
        getStorage().removeItem(`${storagePrefix}post-login-redirect`);
    },
};

export default storage;

import { useCallback } from 'react';

import { ParticipantAccessType } from 'types';

import useAppSelector from './useAppSelector';

const useIsAbleTo = (): {
    isAbleTo: (
        /*
            permission='PERMISSION_1' => returns true if permission present in user permissions
            permission=['PERMISSION_1', 'PERMISSION_2'] => returns true if ANY of the permission present in user permissions
        */
        permission: string | string[],
        /* tenantOrgId not used */
        tenantOrgId?: number,
        /* accessType not used */
        accessType?: ParticipantAccessType
    ) => boolean;
} => {
    const { userPermissions } = useAppSelector((state) => state.auth);

    const isAbleTo = useCallback(
        (permission: string | string[]): boolean => {
            if (Array.isArray(permission)) {
                return permission.some((p: string) => userPermissions.includes(p));
            }
            return userPermissions.includes(permission);
        },
        [userPermissions]
    );

    return { isAbleTo };
};

export default useIsAbleTo;

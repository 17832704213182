import { AppConstant } from 'common';
import { axios } from 'lib/axios';
import { UserAccountDetails } from 'types';

export const updateUserAccountDetails = (
    userId: number,
    updateUserPayload: Partial<UserAccountDetails>
): Promise<UserAccountDetails> => {
    return axios.patch(
        `${AppConstant.API_ROUTES.UPDATE_USER_ACCOUNT}/${userId}`,
        updateUserPayload
    );
};

import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { AppConstant, PermissionConstants } from 'common';
import { useAppSelector, useIsAbleTo, useTranslate } from 'hooks';

import UserAccessType from '../../UserAccessType';
import { SVGIcon } from '../SVGIcon';

export const MyAccount = () => {
    const { t } = useTranslate();
    const navigate = useNavigate();
    const { isAbleTo } = useIsAbleTo();
    const { userAccount } = useAppSelector((state) => state.auth);

    if (!userAccount) {
        return <></>;
    }

    return (
        <Dropdown className="custom-dropdown">
            <Dropdown.Toggle
                className="profile-menu"
                id="myacc-dropdown-basic"
                type="button"
                variant="link"
                as="a"
            >
                <SVGIcon icon="ProfileFillIcon" className="svg-icon" />
                {t.MY_ACCOUNT}
            </Dropdown.Toggle>
            <Dropdown.Menu className="pt-0 profile-menu-content" align="end">
                <div className="media mb-2">
                    <div className="media-body d-flex align-items-start justify-content-between w-100">
                        <div>
                            <h5>
                                {userAccount.first_name} {userAccount.last_name}
                            </h5>
                            <p className="text-primary mt-1">{userAccount.email}</p>
                        </div>
                        <UserAccessType />
                    </div>
                </div>
                {isAbleTo(PermissionConstants.MY_USER_VIEW) && (
                    <Dropdown.Item
                        className="active"
                        onClick={() => navigate(AppConstant.ROUTE_PATHS.MY_PROFILE)}
                    >
                        {t.MY_PROFILE}
                        <SVGIcon icon="ProfileFillIcon" className="svg-icon" />
                    </Dropdown.Item>
                )}
                <Dropdown.Item onClick={() => navigate(AppConstant.ROUTE_PATHS.LOGOUT)}>
                    {t.LOGOUT}
                    <SVGIcon icon="LogoutIcon" className="svg-icon" />
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

import { PlatformApp } from './platformApp.interface';
import { ParticipantAccessType } from './vbc.interface';

export interface Organization {
    id: number;
    name: string;
    ein: string;
    address_line_1: string;
    address_line_2: string;
    city: string;
    state: string;
    zip_code: string;
    phone: string;
    validated: boolean;
    disabled: boolean;
    created_by: string;
    created_at: string;
    updated_by: string;
    updated_at: string;
    primary_contact_email: string;
    tenant_app_accesses: TenantAppAccess[];
    is_baa_required: boolean;
    baa_status: string;
    baa_file_name: string;
    baa_file_url: string;
    comment: string;
    baa_reviewed_at: string;
    is_customer: boolean;
    tpa: TPAOrg;
}

export interface TPAOrg {
    id: number;
    organization_id: number;
    config: string;
}

export interface UserOrganization {
    user_id: number;
    organization_id: number;
    disabled: boolean;
    primary_organization: boolean;
    organization: Organization;
}

export interface UpdateOrganizationPayload {
    address_line_1: string;
    address_line_2: string;
    city: string;
    state: string;
    zip_code: string;
    phone: string;
    is_customer?: boolean;
}

export interface ConfirmOrganizationPayload extends UpdateOrganizationPayload {
    name: string;
    ein: string;
    validated: boolean;
}

export interface TenantAppAccess {
    id: number;
    disabled: boolean;
    tenant_app_id: number;
    organization_id: number;
    organization_group_id: number;
    created_by: string;
    created_at: string;
    updated_by: string;
    updated_at: string;
    access_type: ParticipantAccessType;
    delegate_user_email: string | null;
    tenant_app: PlatformApp;
}

export enum BAAStatus {
    NOT_REQUIRED = 'Not Required',
    PENDING = 'Pending Review',
    APPROVED = 'Approved',
    REJECTED = 'Rejected',
}

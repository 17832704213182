import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PlatformApp } from 'types';

interface PlatformAppsState {
    platformApps: PlatformApp[];
    myApps: PlatformApp[];
    editAppId?: number;
}

// Define the initial state using that type
const initialState: PlatformAppsState = {
    platformApps: [],
    myApps: [],
};

export const platformAppsSlice = createSlice({
    name: 'platformApps',
    initialState,
    reducers: {
        setPlatformApps: (state, { payload }: PayloadAction<PlatformApp[]>) => {
            state.platformApps = payload;
        },
        setMyApps: (state, { payload }: PayloadAction<PlatformApp[]>) => {
            state.myApps = payload;
        },
        setEditAppId: (state, { payload }: PayloadAction<number | undefined>) => {
            state.editAppId = payload;
        },
    },
});

export const { setPlatformApps, setMyApps, setEditAppId } = platformAppsSlice.actions;

export default platformAppsSlice.reducer;
